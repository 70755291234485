exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktualnosci-czuwanie-tsx": () => import("./../../../src/pages/aktualnosci/czuwanie.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-czuwanie-tsx" */),
  "component---src-pages-aktualnosci-dokumenty-tsx": () => import("./../../../src/pages/aktualnosci/dokumenty.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-dokumenty-tsx" */),
  "component---src-pages-aktualnosci-formacja-tsx": () => import("./../../../src/pages/aktualnosci/formacja.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-formacja-tsx" */),
  "component---src-pages-aktualnosci-intencje-tsx": () => import("./../../../src/pages/aktualnosci/intencje.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-intencje-tsx" */),
  "component---src-pages-aktualnosci-msze-sw-tsx": () => import("./../../../src/pages/aktualnosci/msze-sw.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-msze-sw-tsx" */),
  "component---src-pages-aktualnosci-rekolekcje-tsx": () => import("./../../../src/pages/aktualnosci/rekolekcje.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-rekolekcje-tsx" */),
  "component---src-pages-aktualnosci-rowery-tsx": () => import("./../../../src/pages/aktualnosci/rowery.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-rowery-tsx" */),
  "component---src-pages-aktualnosci-slowo-ks-proboszcza-tsx": () => import("./../../../src/pages/aktualnosci/slowo-ks-proboszcza.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-slowo-ks-proboszcza-tsx" */),
  "component---src-pages-aktualnosci-transmisja-tsx": () => import("./../../../src/pages/aktualnosci/transmisja.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-transmisja-tsx" */),
  "component---src-pages-aktualnosci-triduum-tsx": () => import("./../../../src/pages/aktualnosci/triduum.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-triduum-tsx" */),
  "component---src-pages-aktualnosci-wizyta-duszpasterska-tsx": () => import("./../../../src/pages/aktualnosci/wizyta-duszpasterska.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-wizyta-duszpasterska-tsx" */),
  "component---src-pages-aktualnosci-wypominki-tsx": () => import("./../../../src/pages/aktualnosci/wypominki.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-wypominki-tsx" */),
  "component---src-pages-duszpasterze-i-wspolnoty-duszpasterze-tsx": () => import("./../../../src/pages/duszpasterze-i-wspolnoty/duszpasterze.tsx" /* webpackChunkName: "component---src-pages-duszpasterze-i-wspolnoty-duszpasterze-tsx" */),
  "component---src-pages-duszpasterze-i-wspolnoty-grupy-parafialne-tsx": () => import("./../../../src/pages/duszpasterze-i-wspolnoty/grupy-parafialne.tsx" /* webpackChunkName: "component---src-pages-duszpasterze-i-wspolnoty-grupy-parafialne-tsx" */),
  "component---src-pages-duszpasterze-i-wspolnoty-siostry-karmelitanki-tsx": () => import("./../../../src/pages/duszpasterze-i-wspolnoty/siostry-karmelitanki.tsx" /* webpackChunkName: "component---src-pages-duszpasterze-i-wspolnoty-siostry-karmelitanki-tsx" */),
  "component---src-pages-historia-historia-parafii-tsx": () => import("./../../../src/pages/historia/historia-parafii.tsx" /* webpackChunkName: "component---src-pages-historia-historia-parafii-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-ogloszenia-tsx": () => import("./../../../src/pages/ogloszenia.tsx" /* webpackChunkName: "component---src-pages-ogloszenia-tsx" */),
  "component---src-pages-polityka-cookies-tsx": () => import("./../../../src/pages/polityka-cookies.tsx" /* webpackChunkName: "component---src-pages-polityka-cookies-tsx" */),
  "component---src-pages-sakramenty-tsx": () => import("./../../../src/pages/sakramenty.tsx" /* webpackChunkName: "component---src-pages-sakramenty-tsx" */),
  "component---src-templates-grupy-parafialne-tsx": () => import("./../../../src/templates/grupy-parafialne.tsx" /* webpackChunkName: "component---src-templates-grupy-parafialne-tsx" */),
  "component---src-templates-ogloszenia-tsx": () => import("./../../../src/templates/ogloszenia.tsx" /* webpackChunkName: "component---src-templates-ogloszenia-tsx" */),
  "component---src-templates-sakramenty-tsx": () => import("./../../../src/templates/sakramenty.tsx" /* webpackChunkName: "component---src-templates-sakramenty-tsx" */)
}

